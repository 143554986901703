<template>
  <Layout>
    <h1 class="uk-heading-small" id="content">Devices</h1>
    <table class="uk-table uk-table-divider">
      <caption></caption>
      <thead>
        <tr>
          <th scope="col" class="w-5 p-3"></th>
          <th scope="col" class="w-25 p-3">Device Name</th>
          <th scope="col" class="w-20 p-3">Lifetime Shots</th>
          <th scope="col" class="w-20 p-3">Status</th>
          <th scope="col" class="w-20 p-3">Last Seen</th>
          <th scope="col" class="w-10 p-3">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr scope="row" v-for="devices in devices" :key="devices.id">
          <td><span v-bind:style="[devices.connected == 'online' ? { 'color': 'Green' } : { 'color': 'Tomato' }]">
              <font-awesome-icon icon="wifi" />
            </span></td>
          <td>{{ devices.devicename }}</td>
          <td>{{ devices.counter }}</td>
          <td>{{ devices.status }}</td>
          <td>{{ format_date(devices.lastseen) }}</td>
          <td>
            <router-link :to="'/devices/' + devices.id">View</router-link>
          </td>
        </tr>
      </tbody>
    </table>
    <div>
</div>
  </Layout>
</template>
  
  
  
<script>

import DeviceService from "../services/device.service";
import EventBus from "../common/EventBus";
import Layout from '../layouts/private.vue';
import {DateTime} from 'luxon';

export default {
  name: "Devices Overview",
  components: { Layout },
  data() {
    return {
      content: "Devices",
      devices: "",
    };
  },
  created() {
    this.devicesRerender(),
      this.timer = setInterval(this.devicesRerender, 3000);
  },
  methods: {
    format_date(value){
         if (value) {
           return DateTime.fromISO(value).toRelative()
          }
      },
    devicesRerender() {
      DeviceService.getDevices().then(
        (response) => {
          this.devices = response.data.results;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
        }
      );
    },
    dismissed() {
      console.log('Alert dismissed')
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    }
  },
  beforeUnmount() {
    this.cancelAutoUpdate();
  }
};

</script>
  